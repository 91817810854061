import { useModal } from 'mui-modal-provider';
import { useQuery } from '@apollo/client';

import { OrderCreateButton as OrderCreateButtonView } from './order-create-button';
import { AskLoginModal } from '../../../../common/ask-login-modal';
import { NotFittedUserModal } from '../not-fitted-user-modal';
import { IOrderCreateButtonProps } from './order-create-button.models';
import { GET_IS_USER_LOGGED_IN } from '../../../../../graphql/user/queries';
import { appointmentSettings } from '../../../../common/appointment-settings';

export const OrderCreateButton = ({ isFitted, onClick, updateAppointments,  appointmentUrl }: IOrderCreateButtonProps): JSX.Element => {
  const { data: { user: { isLoggedIn } } } = useQuery(GET_IS_USER_LOGGED_IN);
  const { showModal } = useModal();
  const handleButtonClick = () => {
    if (!isLoggedIn) {
      showModal(AskLoginModal, {
        description: 'order.loginRegister.modal.description',
      });
    } else {
      if (appointmentUrl) return;
      showModal(NotFittedUserModal, { updateAppointments: updateAppointments! });
    }
  };

  return (
    <OrderCreateButtonView
      handleClick={handleButtonClick}
      isFitted={isFitted}
      onClick={onClick}
    />
  );
};

export const OrderCreateButtonComponent = appointmentSettings(OrderCreateButton);