import { createTheme, ThemeOptions, ComponentsProps } from '@mui/material/styles';

const siteMinWidth = 320;
const siteMaxWidth = 1440;
const siteMediumWidth = 1140;
const breakpointXl = 1024;

const fontFamilies: IFont = {
  primary: '"Pragmatica"',
  backup: '"Open Sans", "Segoe UI", Tahoma, sans-serif',
};

export enum Breakpoints {
  xs = 0,
  sm = 576,
  md = 768,
  lg = 960,
  xl = 1200,
}

export enum CustomBreakpoints {
  smallMobileDown = 321,
  mobileUp = 375,
  landscapeMobileStart = 576,
  landscapeMobileEnd = 749,
  outOfSalesDesktopNarrow = 1430,
  aom1dMyLensesBreakpoint = 1158,
}

const fontSizes = {
  xxxs: 8,
  '3xs': 11,
  xxs: 12,
  xs: 14,
  sm: 16,
  md: 18,
  lg: 20,
  xl: 24,
  xxl: 28,
  '3xl': 32,
  xxxl: 38,
  large: 50,
};

const lineHeights = {
  xxs: '14px',
  xs: '18px',
  sm: '20px',
  md: '22px',
  lg: '24px',
  xl: '32px',
  xxl: '36px',
  xxxl: '48px',
};

const boxShadows = {};

const borderRadiuses = {
  xs: 0,
  sm: 4,
  md: 8,
  lg: 16,
  xl: 60,
  xxl: '50%',
};

const fontWeights = {
  regular: 400,
  medium: 500,
};

export default createTheme({
  siteMinWidth,
  siteMaxWidth,
  siteMediumWidth,
  fontFamilies,
  fontSizes,
  lineHeights,
  boxShadows,
  borderRadiuses,
  fontWeights,
  breakpointXl,
  palette: {
    primary: {
      main: '#00a3e0',
      light: '#5fd0fb',
      dark: '#0a7cc1',
      contrastText: '#9ffff9',
    },
    secondary: {
      main: '#a51890',
      light: 'rgba(219, 40, 53, 0.05)',
      dark: '#18a7b7',
    },
    custom: {
      main: 'linear-gradient(245.79deg, #04b6f8 8.65%, #e75fd0 90.3%)',
      secondary: 'linear-gradient(#4860bd, #415cb9, #4162bb)',
      greyNeutral: '#5a5a5a',
      greyPrimary: '#011e29',
      shadowPrimary: 'rgba(26, 32, 44, 0.1)',
      shadowSecondary: 'rgba(26, 32, 44, 0.06)',
      secondaryLight: 'rgba(160, 44, 141, 0.3)',
      regBanner: '#6d6d6d',
      regSlide: '#ebfaff',
      regSlideTransparent: 'rgba(255, 255, 255, 0.5)',
    },
    grey: {
      50: 'rgba(255, 255, 255, 0.6)',
      100: '#f7f7f7',
      200: '#f2f2f2',
      300: 'rgba(4, 67, 92, 0.08)',
      400: '#ddd',
      500: '#b5b5b7',
      600: 'rgba(0, 0, 0, 0.5)',
      700: '#717171',
      800: '#484848',
      900: '#1b1b1b',
      A100: '#f5fafc',
      A200: '#f1fbff',
      A400: 'rgba(0, 0, 0, 0.04)',
      A700: 'rgba(0, 0, 0, 0.08)',
    },
    action: {
      active: '#072b5c',
    },
    info: {
      main: '#b5e7f9',
      light: '#ebfbfc',
      dark: '#133f7a',
    },
    error: {
      main: '#db2835',
      light: 'rgba(219, 40, 53, 0.05)',
    },
    text: {
      primary: '#222',
      secondary: '#1a202c',
    },
    success: {
      main: '#25ba7a',
    },
  },
  typography: {
    fontFamily: fontFamilies.primary,
    body1: {
      fontSize: '0.875rem',
      letterSpacing: '0.01071em',
      minWidth: siteMinWidth,
      backgroundColor: '#fff',
      '& sup': {
        fontSize: '70%',
      },
    },
    button: {
      letterSpacing: '0.02857em',
    },
  },
  breakpoints: {
    values: {
      xs: Breakpoints.xs,
      sm: Breakpoints.sm,
      md: Breakpoints.md,
      lg: Breakpoints.lg,
      xl: Breakpoints.xl,
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiUseMediaQuery: {
      noSsr: true,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        'html, body, #root': {
          margin: 0,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          minWidth: 'unset',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          minWidth: 'unset',
          maxWidth: '100%',
          transition: 'none',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          minWidth: 'unset',
        },
      },
    },
  },
});

declare module '@mui/material/styles' {
  interface PaletteOptions {
    custom: {
      main: string,
      secondary: string,
      greyNeutral: string,
      greyPrimary: string,
      shadowPrimary: string,
      shadowSecondary: string,
      secondaryLight: string,
      regBanner: string,
      regSlide: string,
      regSlideTransparent: string,
    },
  }
  interface ThemeOptions {
    siteMinWidth: number;
    siteMaxWidth: number;
    siteMediumWidth: number;
    breakpointXl: number;
    fontFamilies: IFont;
    fontSizes: Sizes;
    lineHeights: LineHeightType;
    boxShadows: ICommonInterface;
    borderRadiuses: BorderRadiusType;
    fontWeights: FontWeightType;
    props: ComponentsProps;
  }

  interface Theme extends ThemeOptions {}
  interface Palette extends PaletteOptions {}
}

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends ThemeOptions {}
}

interface ICommonInterface { [key: string]: string | number }
interface IFont { [key: string]: string }
type FontWeightType = Record<'regular' | 'medium', number | undefined>;
type BorderRadiusType = Record<'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl', number | string>;
type Sizes = Record<'xxxs' | '3xs' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | '3xl' | 'xxxl' | 'large', number | string>;
type LineHeightType = Record<'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl', number | string>;
