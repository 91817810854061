import { useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { IMyOrdersProps } from './my-orders.models';
import { Preloader } from '../../../../common/preloader';
import { ContentWrapper } from '../../content-wrapper';
import { MyOrdersList } from './my-orders-list';
import { useStyles } from './my-orders.styles';
import { useGetOrders } from '../../order/hooks/use-get-orders';
import { limitOrders } from './utils/limit-orders';
import { isSingleBrand } from '../../../../../utils/lenses/is-single-brand';
import { LensesBrandType } from '../../../../../graphql/lenses/models/get-lenses.models';
import { C2CMode } from '../../../../common/configuration-settings/configuration-settings.models';

/*
1. render preloader if isLoading is true +
2. render nothing if isError is true +
3. render data if data:
3.1 render 3 orders on desktop +
3.2 render 2 orders on mobile +
3.3. render isActionShow if more than 3 orders on desktop or more than 2 on mobile +
4. render nothing if c2CMode === C2CMode.full
5. render absolutely nothing if isSingleAohm is false +
 */

export const MyOrders = ({
  ordersData, isLoading, isError, isFitted, lensesData, c2CMode,
}: IMyOrdersProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const orderCards = useGetOrders(ordersData);
  const limitedOrders = useMemo(() => {
    return isDesktop
      ? limitOrders(orderCards.orders, 3)
      : limitOrders(orderCards.orders, 2);
  }, [orderCards, isDesktop]);
  const isActionShow = useMemo(() => {
    return isDesktop ? orderCards.orders.length > 3 : orderCards.orders.length > 2;
  }, [orderCards, isDesktop]);

  const renderOrdersList = () => {
    if (isLoading) {
      return <Preloader />;
    }

    if (isError) {
      return null;
    }

    if (!(c2CMode === C2CMode.full || (c2CMode === C2CMode.partial && limitedOrders.length))) {
      return null;
    }

    return (
      <MyOrdersList
        isFitted={isFitted}
        orders={limitedOrders}
        isActionShow={isActionShow}
      />
    );
  };

  // Hide C2C feature from aohm-only fitted users
  const isSingleAohm = isSingleBrand(lensesData!, LensesBrandType.aohm);

  return (
    <>{!isSingleAohm && (
      <section
        className={classes.root}
        data-testid="my-orders"
      >
        <ContentWrapper>
          {renderOrdersList()}
        </ContentWrapper>
      </section>
    )}</>
  );
};
