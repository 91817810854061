import { useStyles } from './facts-about-brand.styles';
import brandPopularity from '../../../../../assets/images/brand-pages/facts-about-brand/brand-popularity.png';
import { useMediaQuery, useTheme } from '@mui/material';
import { Header } from '../../layout-primary/header';
import { Footer } from '../../layout-reg-guide/footer';
import brandPopularityMobile from '../../../../../assets/images/brand-pages/facts-about-brand/brand-popularity-mobile.png';

export const BrandPopularity = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {isMobile && <Header />}
      <section className={classes.root} data-testid="winners-content">
        <img src={isMobile ? brandPopularityMobile : brandPopularity} />
        {isMobile && <Footer showLogo={false} />}
      </section>
    </>
  );
};
