import { useStyles } from './facts-about-brand.styles';
import brand1 from '../../../../../assets/images/brand-pages/facts-about-brand/brand-1.png';
import { useMediaQuery, useTheme } from '@mui/material';
import brand1Mobile from '../../../../../assets/images/brand-pages/facts-about-brand/brand-1-mobile.png';
import { Footer } from '../../layout-reg-guide/footer';
import { Header } from '../../layout-primary/header';

export const Brand1 = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {isMobile && <Header />}
      <section className={classes.root} data-testid="winners-content">
        <img src={isMobile ? brand1Mobile : brand1} />
        {isMobile && <Footer showLogo={false} />}
      </section>
    </>
  );
};
